// import React, { Component } from 'react';
// import  '../index.css';
// import { models } from 'powerbi-client';
// import { PowerBIEmbed } from 'powerbi-client-react';
// import jsonp from 'jsonp';

// export default class Power extends Component {

//   constructor(props){
//     super(props)
//     this.state = {
//       powerURL:"",
//       powerAccessToken:"",
//       powerReportID: ""
//     }
//   }

//   componentDidMount(){
//     jsonp( "https://mayanaitoken.azurewebsites.net/api/HttpTrigger1?code=gviSuz4Ky19RSTp342/TsZfziuHJJhLEw4rYFZuJ6J61FZw8NtyDaA==", {name:'callback'}, (error, data) => {
//       if (error) {
//         console.log("Error =>",error)
//       } else {
//         this.setState({
//           powerURL:data.EmbedUrl,
//           powerAccessToken:data.EmbedToken,
//           powerReportID:data.ReportId
//         })
//       }
//     });
//   } 

//   render() {
//     return (
//       <div style={{height:800 + "px"}} >
//         <PowerBIEmbed
//           embedConfig = {{
//             type: 'report',   // Supported types: report, dashboard, tile, visual and qna
//             id: this.state.powerReportID,
//             embedUrl: this.state.powerURL,
//             accessToken:this.state.powerAccessToken,
//             tokenType: models.TokenType.Embed,
//             height:"800px",
//             settings: {
//               panes: {
//                 filters: {
//                   expanded: true,
//                   visible: false
//                 }
//               },
//               background: models.BackgroundType.Transparent,
//             }
//           }}

//           eventHandlers = { 
//             new Map([
//               ['loaded', function () {console.log('Report loaded');}],
//               ['rendered', function () {console.log('Report rendered');}],
//               ['error', function (event) {console.log(event.detail);}]
//             ])
//           }
      
//           cssClassName = { "powerBi" }
//           getEmbeddedComponent = { (embeddedReport) => {
//             this.report = embeddedReport;
//           }}
//       />
//         {/* <iframe 
//           title="MyPowerBIFrame"
//           width="100%"
//           //src="https://app.powerbi.com/reportEmbed?reportId=d85dd2e7-6321-4929-a009-a8d664c03474&autoAuth=true&ctid=5355cd23-18bb-4f11-836c-364c3176d0cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" 
//           src="https://app.powerbi.com/reportEmbed?reportId=211cac13-f80c-499f-92a7-b817d9da03c8&autoAuth=true&ctid=5355cd23-18bb-4f11-836c-364c3176d0cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" 
//           frameborder="0" 
//           allowFullScreen="true"
//         >

//         </iframe> */}

//       </div>
      
//     );
//   };
// }

// {} we use this becuase is not default
import React, { Component } from 'react';
import '../index.css';

class Power extends Component {

  //Get API

  render() {
    return (
      <div className="containerPowerBI">
        <iframe 
          title="MyPowerBIFrame"
          width="100%"
          //src="https://app.powerbi.com/reportEmbed?reportId=d85dd2e7-6321-4929-a009-a8d664c03474&autoAuth=true&ctid=5355cd23-18bb-4f11-836c-364c3176d0cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" 
          src="https://app.powerbi.com/reportEmbed?reportId=211cac13-f80c-499f-92a7-b817d9da03c8&autoAuth=true&ctid=5355cd23-18bb-4f11-836c-364c3176d0cf&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" 
          frameborder="0" 
          allowFullScreen="true"
        >

        </iframe>
      </div>
      
    );
  };
}


// Export in the class counter
export default Power;