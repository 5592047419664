import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";

import '../index.css';
import deep_blanco from '../images/logo_deep_blanco.png';

import Geocode from './geocode';
import Power from './powerbi';

import Spinner from 'react-bootstrap/Spinner';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import AuthService from '../services/AuthService';
import User from '../context/auth/authContext';


const UserView = () => {
  const location = useLocation();
  const history = useHistory();
  const { user, getUserData } = React.useContext(User);

  const [showPowerBI, setShowPowerBI] = useState(false);
  const [showTypeform, setShowTypeform] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ userName, setUserName ] = useState('');
  const [ open, setOpen ] = useState(false);


  const auth = new AuthService();


  useEffect(()=>{
    console.log("USING SOME OF THOSE THINGS ?",user,localStorage, User,getUserData)
    if(user){
      console.log("USER =====> ",user)
      setShowPowerBI(user.can_view_dashboard);
      setShowTypeform(user.can_answer_survey);
      setUserName(user.username);
    }
  },[user])

  useEffect(()=>{
    getUserData()
  },[getUserData])
    

//     const getInfo = async () => {
//       var allowedPower = await (store.get('can_view_dashboard') === true);
//       var allowedTypeForm = await (store.get('can_answer_survey') === true);
//       var username = await store.get('username');
  
//       try {
//         setShowPowerBI(allowedPower);
//         setShowTypeform(allowedTypeForm);
//         setUserName(username);
//       } catch (error) {
//         console.log(error);
//       }
//     }
//     else{
//        powerBI = localStorage.getItem(key)
//     }
//     // const typeForm = localStorage.getItem('can_answer_survey')
//     //const userName = localStorage.getItem('username')
//     if (localStorage.length>0) {
//       if(key=="can_view_dashboard"){
//         setShowPowerBI(newpowerBI) ;

//       }
//       if(key =="can_answer_survey"){
//         setShowTypeform(newpowerBI) ;

//       }
//       if(key =="username"){
//         setUserName(powerBI) ;

//       }
//       // setShowTypeform(typeForm);
//       // setUserName(userName);
//     }
//  }

//   }, []);

  //Funcion para cerrar sesion 
  const cerrarSesion = () => {
    // store.remove('token');
    localStorage.clear();
    window.location.replace(`${window.location.origin}/`);
  }

  //Notificacion de cerrar sesion
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? 
        (<div className="spinner">
            <Spinner animation="border" role="status" variant="dark">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
      ) : 
      (
        <> 
          <nav className="navbar navbar-light">
            <div className="powered_container">
              <h1 className="powered">Powered by </h1>
              <img src={deep_blanco} alt="deep_dive" className="powered_deep"/>
            </div>
            {/* <button 
              type="button" 
              className="btn btn-danger logout"
              //style={{fontWeight: "bold", width: "6rem", backgroundColor: '#0A6CB4'}}
              onClick={cerrarSesion}
            >Logout</button> */}
            <div>
              <Button 
                color="primary" 
                onClick={handleClickOpen}
                style={{backgroundColor: '#808080', color: 'white', fontWeight: 'bold'}}
              >Sign Out</Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"¿Seguro que quieres cerrar sesión?"}</DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={cerrarSesion} color="primary">
                    Cerrar sesión
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </nav>

          {showPowerBI ? <Power /> : null}
          {showTypeform && <Geocode
              userName={userName}
          />}
        </>
        )
      }
    </>
  );
}
 
export default UserView;