// {} we use this becuase is not default
import React from 'react';
import { geolocated } from 'react-geolocated';

const Geocode = ({userName, isGeolocationAvailable, isGeolocationEnabled, coords}) => {
    console.log('userName, isGeolocationAvailable, isGeolocationEnabled, coords', userName, isGeolocationAvailable, isGeolocationEnabled, coords);

    
    return !isGeolocationAvailable ? (
        <h1>Tu navegador no soporta Geolocalización</h1>
    ) : !isGeolocationEnabled ? (
        <h1>Geolocalización no está habilitada</h1>
    
    ) : coords ? (
       
      <iframe
        title="Survey"
        width="100%"
        height="550"
        src={`https://deepdivedatascienceapi.typeform.com/to/qyhOUvOi#latitud=${coords.latitude}&longitud=${coords.longitude}&usuario=${userName}`}
      />
    ) : ( <div>Obteniendo datos de localización&hellip; </div> ); 
    
}


// Export in the class Geocode
export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: null,
})(Geocode);