import React from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from 'react-router-dom';
import {UserProvider} from './context/auth/authContext'

import Login from './components/Login';
import UserView from './components/User';
import Transition from './components/transition';

function App() {
  // if(localStorage.length === 0){
  //   window.location.href = history.push(`${window.location.origin}/login`); 
  // }
  // if(window.location.origin.href !== `${window.location.origin}/`){
  //   window.location.href =`${window.location.origin}/` 
  // }

  return (
    <>
    <UserProvider>
    <Router>
        <Switch>
          <Route exact path="/dashboard" component={UserView}/>
          <Route exact path="/" component={Login} />
          <Route exact path="/callback" component = {Transition} />
        </Switch>
      </Router>
    </UserProvider>

    </>
  );
}

export default App;
