import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import deep_blanco from '../images/logo_deep_blanco.png';


const Transition = () => {
    const [ open, setOpen ] = useState(false);

    const cerrarSesion = () => {
        // store.remove('token');
        localStorage.clear();
        window.location.replace(`${window.location.origin}/`);
      }

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    return(
        <>
        <nav className="navbar navbar-light">
        <div className="powered_container">
          <h1 className="powered">Powered by </h1>
          <img src={deep_blanco} alt="deep_dive" className="powered_deep"/>
        </div>
        {/* <button 
          type="button" 
          className="btn btn-danger logout"
          //style={{fontWeight: "bold", width: "6rem", backgroundColor: '#0A6CB4'}}
          onClick={cerrarSesion}
        >Logout</button> */}
        <div>
          <Button 
            color="primary" 
            onClick={handleClickOpen}
            style={{backgroundColor: '#808080', color: 'white', fontWeight: 'bold'}}
          >Sign Out</Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"¿Seguro que quieres cerrar sesión?"}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={cerrarSesion} color="primary">
                Cerrar sesión
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </nav>

        <div className="spinner">
            <Spinner animation="border" role="status" variant="dark">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    </>
    )
}

export default Transition;