import React, { useEffect } from 'react';
import '../index.css';
import { motion } from 'framer-motion';
import AuthService from '../services/AuthService'
import User from '../context/auth/authContext';


const Login = () => {

  const auth = new AuthService();
  const { user, getUserData } = React.useContext(User);

  useEffect(()=>{
    localStorage.clear();
    auth.lock.show();
  },[])

  // useEffect(()=>{
  //   console.log("USING SOME OF THOSE THINGS ?",user,localStorage, User,getUserData)
  //   if(user){
  //     console.log("USER =====> ",user)
  //   }
  // },[user])

  // useEffect(()=>{
  //   getUserData(localStorage.getItem('token'))
  // },[getUserData])
    

  return (
    <div className="bgImage">
      <motion.div className="form-usuario" 
        initial={{ y: -500, opacity: 0}}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.8 }}
      >
        
      </motion.div>
    </div>
  );
}
 
export default Login;