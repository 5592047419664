import Auth0Lock from 'auth0-lock'
import logo from '../images/deep_dive.png';
//import auth_config from '.'
//import { api, alba } from '../Model'

let token = "";

export default class AuthService {
  constructor () {

    
    this.lock = new Auth0Lock('IU45NdbTvbzJ32Th6Bn5oapdjmulq7lc','auth.mayanai.com', {
      auth: {
        //responseMode: "form_post",
        responseType: 'token id_token',
        redirectUrl: `${window.location.origin}/dashboard`,
        params: {
          scope: 'openid profile email'
        }
      },
      //Conections for the future use ['google-oauth2', facebook]
      allowedConnections: ['Username-Password-Authentication'],
      allowShowPassword: true,
      // allowSignUp: false,
      // allowLogin: true,
      initialScreen: "login",
      theme: {
        logo,
        primaryColor: '#808080',
      },
      language: 'es',
      languageDictionary: {
        title: 'MayanAI',
      },
      closable: false,
      autoclose:true
    })
    this.lock.on('authenticated', this.doAuthentication);
    this.lock.on('authorization_error',this.doSomethingElse);

  }

  doSomethingElse = (error) =>{
    console.log("HERE",error)
  }

  doAuthentication = async (authResult) => {
    if(authResult!==undefined){

      
      token = authResult;
      //localStorage.setItem('token',token.idToken);
      //let user = userData.getUserData(token.idToken);
      //console.log("HERE !!!",user)

      // if(token.idToken!==undefined){
      //   if(window.location.origin.href !== `${window.location.origin}/dashboard`){
      //     window.location.href =`${window.location.origin}/dashboard` 
      //   }   
      // }
      console.log("ALL AUTH RESULT ==>",token)
      console.log("AUTH RESULT ==>",token.accessToken)
      fetch(`https://p2zrxsc2va.execute-api.us-west-2.amazonaws.com/api/v1/user/user`, {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }).then(response => response.json())
          .then(data => {
            console.log("data ===>",data);
            const { can_answer_survey, can_view_dashboard, username } = data;
            localStorage.setItem('can_answer_survey', can_answer_survey);
            localStorage.setItem('can_view_dashboard', can_view_dashboard);
            localStorage.setItem('username', username);
            if(window.location.origin.href !== `${window.location.origin}/dashboard`){
              window.location.href =`${window.location.origin}/dashboard` 
            }
          });
    }
  }

  getProfile = () => JSON.parse(localStorage.getItem('profile'))

  login = (language) => {
    this.lock.show({ language })
  }

  loggedIn = () => !!this.getToken()

  setToken = (idToken) => {
    localStorage.setItem('id_token', idToken)
  }

  getToken = () => localStorage.getItem('can_view_dashboard');

  logout = () => {
    localStorage.removeItem('id_token')
  }
}
